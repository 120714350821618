<template>
  <v-app-bar color="#00A9FF" app>
    <v-app-bar-nav-icon
      @click="Tdrawer()"
      class="hidden-md-and-up white--text"
    ></v-app-bar-nav-icon>

    <v-toolbar-title>
      <h3 class="white--text">E-Career Development</h3>
      <div class="caption mt-n1 text--disabled">
          Versi {{ appVersion }}
      </div>
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <div class="hidden-sm-and-down">
      <v-btn class="mr-1" text to="/dashboard" dark>
        <strong>Dashboard</strong>
      </v-btn>

      <v-btn class="mr-1" text to="/unor" dark>
        <strong>Unor</strong>
      </v-btn>

      <v-menu open-on-hover bottom offset-y transition="slide-y-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on" dark>
            <strong>Data Jabatan</strong>
            <v-icon right> mdi-chevron-down </v-icon>
          </v-btn>
        </template>

        <v-list dense class="px-2">
          <!-- <v-list-item to="/jabatan/pimpinantinggi">
            <v-list-item-title>Pimpinan Tinggi</v-list-item-title>
          </v-list-item>

          <v-list-item to="/jabatan/administrator">
            <v-list-item-title>Administrator</v-list-item-title>
          </v-list-item>

          <v-list-item to="/jabatan/pengawas">
            <v-list-item-title>Pengawas</v-list-item-title>
          </v-list-item> -->

          <v-list-item to="/jabatan/pelaksana">
            <v-list-item-title>Pelaksana</v-list-item-title>
          </v-list-item>

          <v-list-item to="/jabatan/fungsional">
            <v-list-item-title>Fungsional</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-menu open-on-hover bottom offset-y transition="slide-y-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on" dark>
            <strong>Analisis Jabatan</strong>
            <v-icon right> mdi-chevron-down </v-icon>
          </v-btn>
        </template>

        <v-list dense class="px-2">
          <v-list-item to="/anjab/petajabatan">
            <v-list-item-title>Peta Jabatan</v-list-item-title>
          </v-list-item>

          <!-- <v-list-item to="/anjab/infojabatan" disabled>
            <v-list-item-title>Informasi Jabatan</v-list-item-title>
          </v-list-item> -->

          <v-list-item to="/anjab/mutasi">
            <v-list-item-title>Mutasi ASN</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-menu open-on-hover bottom offset-y transition="slide-y-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on" dark>
            <strong>Kompetensi</strong>
            <v-icon right> mdi-chevron-down </v-icon>
          </v-btn>
        </template>

        <v-list dense class="px-2">
          <v-list-item to="/" disabled>
            <v-list-item-title>SKM-PNS</v-list-item-title>
          </v-list-item>

          <v-list-item to="/" disabled>
            <v-list-item-title>Kesenjangan Jabatan</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-menu open-on-hover bottom offset-y transition="slide-y-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on" dark>
            <strong>Laporan</strong>
            <v-icon right> mdi-chevron-down </v-icon>
          </v-btn>
        </template>

        <v-list dense class="px-2">
          <v-list-item to="/" disabled>
            <v-list-item-title>Aparatur</v-list-item-title>
          </v-list-item>

          <v-list-item to="/" disabled>
            <v-list-item-title>Jabatan</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </v-app-bar>
</template>

<script>
import Sdrawer from "@/store/drawer";
import { version } from "../../package";

export default {
  data: () => ({
    appVersion: version,
  }),

  async mounted() {},

  methods: {
    Tdrawer() {
      Sdrawer.commit("toggle", !Sdrawer.state.drawer);
    },
  },
};
</script>
