<template>
  <v-app id="inspire">
    <CHeader />

    <CMenu />

    <v-main class="mt-5 pb-10" style="background-color: #f0f0f0">
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>

    <v-footer padless>
      <v-col class="text-center" cols="12">
        &copy; Copyright
        <b>
          2023 BKPSDM - Kabupaten Pohuwato
        </b>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import CHeader from "@/layout/header";
import CMenu from "@/layout/menu";

export default {
  components: {
    CHeader,
    CMenu,
  },

  data: () => ({}),

  created() {
    if (this.$route.path === "/") {
      this.$router.push("/dashboard").catch(() => {});
    }
  },
};
</script>
